import { debounce } from 'patronum';
import {
  createEvent,
  createStore,
  createEffect,
  combine,
  sample,
} from 'effector';
import { actions as drawerActions } from 'src/effector/drawer';
import { stores as featuredFiltersStores } from 'src/effector/products/filters/featured';
import {
  stores as taxonFiltersStores,
  actions as taxonFiltersActions,
} from 'src/effector/products/filters/taxon';
import { stores as pricerangeStores } from 'src/effector/products/filters/pricerange';
import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import reduce from 'lodash/reduce';
import keys from 'lodash/keys';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import map from 'lodash/map';
import toPairs from 'lodash/toPairs';
import countBy from 'lodash/countBy';
import mapValues from 'lodash/mapValues';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import compact from 'lodash/compact';
import orderBy from 'lodash/orderBy';
import intersectionWith from 'lodash/intersectionWith';
import differenceBy from 'lodash/differenceBy';
import first from 'lodash/first';
import reduceRight from 'lodash/reduceRight';
import toLower from 'lodash/toLower';
import uniqBy from 'lodash/uniqBy';

const LabelsToModify = {
  WheelBrand: 'Brand',
  TreadType: 'Tread Type',
  SpeedRating: 'Speed Rating',
  LoadIndex: 'Load Index',
  Sidewall: 'Side Wall',
  MileageWarranty: 'Mileage Warranty',
  Trailer: 'Trailer Tires',
  RoadHazard: 'Free Road Hazard',
  RoadsideAssistance: 'Free Roadside Assistance',
  FuelEfficient: 'Fuel Efficient',
  WinterCertified: 'Severe Snow Rated All Season',
  RunFlat: 'Run Flat',
};

const labelToRender = label => LabelsToModify[label] || label;

const openFilterSortDrawer = createEvent();
const initProducts = createEvent();
const initSimpleProducts = createEvent();
const initStaggeredProducts = createEvent();
const changeSortDirection = createEvent();
const viewMore = createEvent();

const $products = createStore([]).on(initSimpleProducts, (_, v) => v);
const $staggeredProducts = createStore([]).on(
  initStaggeredProducts,
  (_, v) => v,
);

sample({
  clock: openFilterSortDrawer,
  target: drawerActions.open.prepend(() => ({
    type: 'filters',
    origin: 'left',
  })),
});

sample({
  clock: initProducts,
  filter: data => first(data)?.staggered,
  target: initStaggeredProducts,
});

sample({
  clock: initProducts,
  fn: products => products[0]?.category,
  target: taxonFiltersStores.$productsCategory,
});

sample({
  clock: initProducts,
  filter: data => first(data)?.staggered,
  fn: data =>
    flatMap(data, item => [
      mapValues(item, value => (value?.front ? get(value, 'front') : value)),
      mapValues(item, value => (value?.rear ? get(value, 'rear') : value)),
    ]),
  target: initSimpleProducts,
});

sample({
  clock: initProducts,
  filter: data => !first(data)?.staggered,
  target: initSimpleProducts,
});

const $areStaggered = $products.map(data => first(data)?.staggered || false);

const changeSizeStaggered = createEvent();
const initSizeStaggered = createEvent();
const initSizeStaggeredOptions = createEvent();
const $sizeStaggered = createStore('')
  .on(initSizeStaggered, (_, v) => v)
  .on(changeSizeStaggered, (_, v) => v);
const $sizeStaggeredOptions = createStore([]).on(
  initSizeStaggeredOptions,
  (_, v) => v,
);

const $priceRangeDebounced = createStore(pricerangeStores.$priceRange);
const $isLoading = createStore(false)
  .on(changeSizeStaggered, () => true)
  .on(pricerangeStores.$priceRange, () => true)
  .reset($priceRangeDebounced);

const $sortDirection = createStore('asc')
  .reset(taxonFiltersActions.clearFilters)
  .on(changeSortDirection, (_, v) => v);

const getProductsByIds = (ids, products) =>
  map(ids, id => find(products, p => p.id === id));

const $productsByFeaturedFilter = combine(
  {
    products: $products,
    dealsIds: featuredFiltersStores.$rebateIds,
    bestWarrantyIds: featuredFiltersStores.$bestWarrantyIds,
    allWeatherIds: featuredFiltersStores.$allWeatherIds,
    cheapIds: featuredFiltersStores.$cheapIds,
  },
  ({ products, dealsIds, bestWarrantyIds, allWeatherIds, cheapIds }) => ({
    deals: getProductsByIds(dealsIds, products),
    bestWarranty: getProductsByIds(bestWarrantyIds, products),
    allWeather: getProductsByIds(allWeatherIds, products),
    cheap: getProductsByIds(cheapIds, products),
  }),
);

const $recommendedFeaturedProducts = $productsByFeaturedFilter.map(products =>
  compact(map(products, value => (isEmpty(value) ? undefined : value[0]))),
);

const $productsPreparedToBeFilteredByPriceRange = combine(
  {
    productsByFeaturedFilter: $productsByFeaturedFilter,
    value: featuredFiltersStores.$value,
    allProducts: $products,
  },
  ({ productsByFeaturedFilter, value, allProducts }) =>
    productsByFeaturedFilter[value] || allProducts,
);

debounce({
  source: pricerangeStores.$priceRange,
  timeout: 300,
  target: $priceRangeDebounced,
});

const $productsPreparedToBeFilteredByTaxon = combine(
  {
    products: $productsPreparedToBeFilteredByPriceRange,
    priceRange: $priceRangeDebounced,
  },
  ({ products, priceRange }) => {
    const res = products.filter(
      p =>
        Number(p.price) >= priceRange?.min?.price &&
        Number(p.price) <= priceRange?.max?.price,
    );
    return res;
  },
);

const $productsFilteredByTaxon = combine(
  {
    products: $productsPreparedToBeFilteredByTaxon,
    checkedFilters: taxonFiltersStores.$checkedFilters,
  },
  ({ products, checkedFilters }) =>
    products.filter(product =>
      every(checkedFilters, (value, key) =>
        value.includes(product.filters[key]),
      ),
    ),
);

const $shouldRenderWithRecommended = combine(
  [
    featuredFiltersStores.$isEmpty,
    taxonFiltersStores.$emptyCheckedFilters,
    pricerangeStores.$isDefaultPricerange,
  ],
  s => s.every(Boolean),
);

const $sortedProducts = combine(
  {
    products: $productsFilteredByTaxon,
    sortDirection: $sortDirection,
    isEmptyFeaturedFilter: featuredFiltersStores.$isEmpty,
  },
  ({ products, sortDirection, isEmptyFeaturedFilter }) =>
    isEmptyFeaturedFilter
      ? orderBy(
          products,
          [product => parseFloat(product.price)],
          [sortDirection],
        )
      : products,
);

const $sortedProductsWithRecommended = combine(
  {
    recommended: $recommendedFeaturedProducts,
    sorted: $sortedProducts,
  },
  ({ recommended, sorted }) => [
    ...recommended,
    ...differenceBy(sorted, recommended, 'id'),
  ],
);

const $productsToRender = combine(
  {
    shouldRenderWithRecommended: $shouldRenderWithRecommended,
    sortedProducts: $sortedProducts,
    sortedProductsWithRecommended: $sortedProductsWithRecommended,
  },

  ({
    shouldRenderWithRecommended,
    sortedProducts,
    sortedProductsWithRecommended,
  }) =>
    shouldRenderWithRecommended
      ? uniqBy(sortedProductsWithRecommended, 'id')
      : sortedProducts,
);

const $changingCounter = createStore(0).on($productsToRender, i => i + 1);

const $staggeredProductsToRender = combine(
  {
    products: $productsToRender,
    staggeredProducts: $staggeredProducts,
    sortDirection: $sortDirection,
    shouldRenderWithRecommended: $shouldRenderWithRecommended,
    recommendedFeaturedProducts: $recommendedFeaturedProducts,
  },
  ({
    products,
    staggeredProducts,
    sortDirection,
    shouldRenderWithRecommended,
    recommendedFeaturedProducts,
  }) => {
    const ids = products.map(p => p.id);
    const filtered = staggeredProducts.filter(
      p => ids.includes(p.id?.front) || ids.includes(p.id?.rear),
    );

    const sorted = orderBy(
      filtered,
      [
        product =>
          parseFloat(product.price.front) + parseFloat(product.price.rear),
      ],
      [sortDirection],
    );

    if (!shouldRenderWithRecommended) {
      return sorted;
    }
    return reduceRight(
      recommendedFeaturedProducts,
      (acc, item) => {
        const index = findIndex(
          acc,
          product =>
            product.id.front === (item.rebate?.product_id || item.id) ||
            product.id.rear === (item.rebate?.product_id || item.id),
        );

        if (index !== -1) {
          const [recommended] = acc.splice(index, 1);
          acc.unshift(recommended);
        }

        return acc;
      },
      [...sorted],
    );
  },
);

const $numberOfShownProducts = createStore(10)
  .reset($productsToRender)
  .on(viewMore, (prev, newValue) => prev + newValue);

const $numberOfShownStaggeredProducts = createStore(5)
  .reset($staggeredProductsToRender)
  .on(viewMore, (prev, newValue) => prev + newValue);

const $productsToRenderCount = combine(
  {
    productsToRender: $productsToRender,
    staggeredProductsToRender: $staggeredProductsToRender,
    areStaggered: $areStaggered,
  },
  ({ productsToRender, staggeredProductsToRender, areStaggered }) =>
    areStaggered ? staggeredProductsToRender.length : productsToRender.length,
);

const $showViewMoreButton = combine(
  {
    areStaggered: $areStaggered,
    numberOfShownProducts: $numberOfShownProducts,
    numberOfShownStaggeredProducts: $numberOfShownStaggeredProducts,
    productsToRenderCount: $productsToRenderCount,
  },
  ({
    areStaggered,
    numberOfShownProducts,
    numberOfShownStaggeredProducts,
    productsToRenderCount,
  }) =>
    areStaggered
      ? productsToRenderCount > numberOfShownStaggeredProducts
      : productsToRenderCount > numberOfShownProducts,
);

const $showViewMoreButtonDebounced = createStore(true);
debounce({
  source: $showViewMoreButton,
  timeout: 300,
  target: $showViewMoreButtonDebounced,
});

const hasProductsByIds = (ids, products) =>
  !isEmpty(intersectionWith(products, ids, (p, id) => p.id === id));

const $hasAnyFeaturedProducts = combine(
  featuredFiltersStores.$featuredFilterIds,
  $productsToRender,
  (ids, products) => hasProductsByIds(ids, products),
);

const $hasNoCheckedFilters = combine(
  [
    taxonFiltersStores.$emptyCheckedFilters,
    pricerangeStores.$isDefaultPricerange,
    featuredFiltersStores.$isEmpty,
  ],
  i => i.every(Boolean),
);

const showFeaturedLabel = (
  productId,
  ids,
  recommendedFeaturedProducts,
  hasNoCheckedFilters,
  allFeatureProducts = false,
) =>
  (ids.includes(productId) &&
    (allFeatureProducts
      ? true
      : recommendedFeaturedProducts.some(
          product => product.id === productId,
        )) &&
    hasNoCheckedFilters) ||
  (ids.includes(productId) && !hasNoCheckedFilters);

const showCheckbox = (ids, products, emptyTaxonFilter, isDefaultPricerange) =>
  ids.length &&
  (hasProductsByIds(ids, products) ||
    (emptyTaxonFilter && isDefaultPricerange));

const $showCheckbox = combine(
  {
    productsToRender: $productsToRender,
    rebateIds: featuredFiltersStores.$rebateIds,
    bestWarrantyIds: featuredFiltersStores.$bestWarrantyIds,
    cheapIds: featuredFiltersStores.$cheapIds,
    allWeatherIds: featuredFiltersStores.$allWeatherIds,
    emptyTaxonFilter: taxonFiltersStores.$emptyCheckedFilters,
    isDefaultPricerange: pricerangeStores.$isDefaultPricerange,
  },
  ({
    productsToRender,
    rebateIds,
    bestWarrantyIds,
    cheapIds,
    allWeatherIds,
    emptyTaxonFilter,
    isDefaultPricerange,
  }) => ({
    withRebate: showCheckbox(
      rebateIds,
      productsToRender,
      emptyTaxonFilter,
      isDefaultPricerange,
    ),
    withBestWarranty: showCheckbox(
      bestWarrantyIds,
      productsToRender,
      emptyTaxonFilter,
      isDefaultPricerange,
    ),
    withCheap: showCheckbox(
      cheapIds,
      productsToRender,
      emptyTaxonFilter,
      isDefaultPricerange,
    ),
    withAllWeather: showCheckbox(
      allWeatherIds,
      productsToRender,
      emptyTaxonFilter,
      isDefaultPricerange,
    ),
  }),
);

const $availableProducts = combine(
  {
    checkedFilters: taxonFiltersStores.$checkedFilters,
    products: $productsPreparedToBeFilteredByTaxon,
  },
  ({ checkedFilters, products }) => {
    if (isEmpty(checkedFilters)) {
      return products;
    }

    return reduce(
      checkedFilters,
      (acc, value, key) => {
        const filtersToCheck = omit(checkedFilters, key);
        const prods = products.filter(p => {
          return every(filtersToCheck, (v, k) => v.includes(p.filters[k]));
        });

        return [...new Set([...acc, ...prods].map(JSON.stringify))].map(
          JSON.parse,
        );
      },
      [],
    );
  },
);

const getUniqueFields = products => {
  const pairs = flatMap(compact(products), p => toPairs(p.filters));
  const groups = groupBy(pairs, pair => pair[0]);
  const result = mapValues(groups, group => ({
    label: group[0][0],
    fields: countBy(group.map(item => item[1])),
  }));
  return result;
};

const $availableFilters = $availableProducts.map(getUniqueFields);
const $selectedFilters = $productsToRender.map(getUniqueFields);
const $allFilters = $products.map(getUniqueFields);

sample({
  source: {
    checkedFilters: taxonFiltersStores.$checkedFilters,
    availableFilters: $availableFilters,
    selectedFilters: $selectedFilters,
    allFilters: $allFilters,
    hasNoCheckedFilters: $hasNoCheckedFilters,
  },
  fn: ({
    checkedFilters,
    availableFilters,
    selectedFilters,
    allFilters,
    hasNoCheckedFilters,
  }) => {
    const getFields = (fields, label) =>
      map(fields, (value, key) => {
        const checked = !!checkedFilters[label]?.includes(key);
        const notInSelectedGroup = isEmpty(checkedFilters[label]);
        return {
          label: key,
          product_count:
            checked || notInSelectedGroup
              ? selectedFilters[label]?.fields[key] || 0
              : fields[key] || 0,
          checked,
        };
      });

    const filters = map(availableFilters, ({ fields, label }) => {
      const extraFields = allFilters[label]?.fields;
      const fieldsToAdd = mapValues(omit(extraFields, keys(fields)), () => 0);
      const fieldsToRender = getFields({ ...fields, ...fieldsToAdd }, label);

      return {
        label,
        fields: fieldsToRender,
        checkedFieldsCount: fieldsToRender.filter(f => f.checked).length,
      };
    });

    if (keys(checkedFilters).length === 1 && hasNoCheckedFilters) {
      const allFiltersToRender = map(allFilters, ({ fields, label }) => {
        const fieldsToRender = getFields(fields, label);
        return {
          label,
          fields: fieldsToRender,
          checkedFieldsCount: fieldsToRender.filter(f => f.checked).length,
        };
      });

      const labelToReplace = keys(checkedFilters)[0];
      const indexToReplace = findIndex(filters, { label: labelToReplace });
      const replacementItem = find(allFiltersToRender, {
        label: labelToReplace,
      });

      if (indexToReplace !== -1 && replacementItem) {
        filters[indexToReplace] = replacementItem;
      }
    }

    const sortedFilters = filters.map(f => ({
      ...f,
      fields: orderBy(f.fields, i => toLower(i.label)),
    }));
    return orderBy(sortedFilters, f => labelToRender(f.label));
  },
  target: taxonFiltersStores.$filtersToRender,
});

const $checkedFiltersCount = combine(
  {
    checkedFilters: taxonFiltersStores.$checkedFiltersArray,
    isDefaultPricerange: pricerangeStores.$isDefaultPricerange,
  },
  ({ checkedFilters, isDefaultPricerange }) =>
    checkedFilters.length + Number(!isDefaultPricerange),
);

const watchAffirmTextFx = createEffect().use(() => {
  affirm.ui.ready(() => {
    try {
      const el = document.querySelector('.affirm-as-low-as');
      const observer = new MutationObserver((mutationList, observer) => {
        document.querySelector(
          '.affirm-as-low-as .affirm-ala-price',
        ).nextSibling.textContent = '/month or 0% APR with ';
      });

      observer.observe(el, {
        attributes: false,
        childList: true,
        subtree: false,
      });
    } catch (e) {
      console.error(e);
    }
  });
});

const watchAffirmText = createEvent();

sample({
  clock: watchAffirmText,
  target: watchAffirmTextFx,
});

export const stores = {
  $products,
  $staggeredProducts,
  $availableProducts,
  $sortedProducts,
  $productsToRender,
  $staggeredProductsToRender,
  $sortDirection,
  $numberOfShownProducts,
  $numberOfShownStaggeredProducts,
  $hasAnyFeaturedProducts,
  $availableFilters,
  $recommendedFeaturedProducts,
  $sizeStaggered,
  $sizeStaggeredOptions,
  $isLoading,
  $areStaggered,
  $productsToRenderCount,
  $changingCounter,
  $checkedFiltersCount,
  $productsByFeaturedFilter,
  $shouldRenderWithRecommended,
  $showCheckbox,
  $hasNoCheckedFilters,
  $showViewMoreButtonDebounced,
};

export const store = combine(stores);

export const actions = {
  openFilterSortDrawer,
  initProducts,
  changeSortDirection,
  labelToRender,
  viewMore,
  initSizeStaggered,
  initSizeStaggeredOptions,
  changeSizeStaggered,
  watchAffirmText,
  showFeaturedLabel,
};
